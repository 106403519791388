@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;


.custom-card {
    border-color: rgb(104, 104, 104) !important;
    border-bottom-width: 1px !important;
    border-top-width: 2px !important;
}